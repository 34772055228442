import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store/index";

Vue.use(VueRouter)

// function hasQueryParams(route) {
//   return !!Object.keys(route.query).length
// }

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    // Questionnaires
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'All questionnaires',
        breadcrumb: [
          {
            text: 'All questionnaires',
            active: true
          },
        ],
        requiresAuth: true
      },
    },
    {
      path: '/questionnaire-single/:id',
      name: 'questionnaire-single',
      component: () => import('@/views/questionnaire/QuestionnaireSingle.vue'),
      meta: {
        pageTitle: 'Questionnaires',
        breadcrumb: [
          {
            text: 'All questionnaires',
            active: true
          },
          {
            text: 'Questionnaires',
            active: true
          },
        ],
        requiresAuth: true
      },
    },
    {
      path: '/questionnaire-create',
      name: 'questionnaire-create',
      component: () => import('@/views/questionnaire/QuestionnaireCreate.vue'),
      meta: {
        pageTitle: 'Questionnaires',
        breadcrumb: [
          {
            text: 'All questionnaires',
            active: true
          },
          {
            text: 'Create new questionnaire',
            active: true
          },
        ],
        requiresAuth: true
      },
    },
    {
      path: '/questionnaire-feed',
      name: 'questionnaire-feed',
      component: () => import('@/views/questionnaire/QuestionnaireFeed.vue'),
      meta: {
        pageTitle: 'Questionnaires',
        breadcrumb: [
          {
            text: 'Completed questionnaires',
            active: true
          }
        ],
        requiresAuth: true
      },
    },
    // Questionnaire Messages
    {
      path: '/questionnaire-single/:id/messages',
      name: 'questionnaire-messages',
      component: () => import('@/views/questionnaire/messages/Messages.vue'),
      meta: {
        pageTitle: 'Questionnaire Messages',
        breadcrumb: [
          {
            text: 'Questionnaire Messages',
            active: true
          }
        ],
        requiresAuth: true
      },
    },

    // Users
    {
      path: '/users',
      name: 'user-list',
      component: () => import('@/views/user/UserList.vue'),
      meta: {
        pageTitle: 'Users',
        breadcrumb: [
          {
            text: 'All users',
            active: true
          },
        ],
        requiresAuth: true
      },
    },
    {
      path: '/users/:id',
      name: 'user-update',
      component: () => import('@/views/user/UserUpdate.vue'),
      meta: {
        pageTitle: 'Update user',
        breadcrumb: [
          {
            text: 'Update user',
            active: true
          },
        ],
        requiresAuth: true
      },
    },
    // Admins
    {
      path: '/admins',
      name: 'admin-list',
      component: () => import('@/views/user/admin/AdminList.vue'),
      meta: {
        pageTitle: 'Admins',
        breadcrumb: [
          {
            text: 'All admins',
            active: true
          },
        ],
        requiresAuth: true
      },
    },
    {
      path: '/admins/create',
      name: 'admin-create',
      component: () => import('@/views/user/admin/AdminCreate.vue'),
      meta: {
        pageTitle: 'Create new admin',
        breadcrumb: [
          {
            text: 'Create new admin',
            active: true
          },
        ],
        requiresAuth: true
      },
    },
    {
      path: '/admins/:id',
      name: 'admin-update',
      component: () => import('@/views/user/admin/AdminUpdate.vue'),
      meta: {
        pageTitle: 'Update admin',
        breadcrumb: [
          {
            text: 'Update admin',
            active: true
          },
        ],
        requiresAuth: true
      },
    },
    // Professionals
    {
      path: '/professionals',
      name: 'professional-list',
      component: () => import('@/views/user/professional/ProfessionalList.vue'),
      meta: {
        pageTitle: 'Professionals',
        breadcrumb: [
          {
            text: 'All professionals',
            active: true
          },
        ],
        requiresAuth: true
      },
    },
    {
      path: '/professionals/create',
      name: 'professional-create',
      component: () => import('@/views/user/professional/ProfessionalCreate.vue'),
      meta: {
        pageTitle: 'Create new professional',
        breadcrumb: [
          {
            text: 'Create new professional',
            active: true
          },
        ],
        requiresAuth: true
      },
    },
    {
      path: '/professionals/:id',
      name: 'professional-update',
      component: () => import('@/views/user/professional/ProfessionalUpdate.vue'),
      meta: {
        pageTitle: 'Update professional',
        breadcrumb: [
          {
            text: 'Update professional',
            active: true
          },
        ],
        requiresAuth: true
      },
    },
    // Roles
    {
      path: '/roles',
      name: 'role-list',
      component: () => import('@/views/user/role/RoleList.vue'),
      meta: {
        pageTitle: 'Roles',
        breadcrumb: [
          {
            text: 'All roles',
            active: true
          },
        ],
        requiresAuth: true
      },
    },
    {
      path: '/roles/create',
      name: 'role-create',
      component: () => import('@/views/user/role/RoleCreate.vue'),
      meta: {
        pageTitle: 'Create new role',
        breadcrumb: [
          {
            text: 'Create new role',
            active: true
          },
        ],
        requiresAuth: true
      },
    },
    {
      path: '/roles/:id',
      name: 'role-update',
      component: () => import('@/views/user/role/RoleUpdate.vue'),
      meta: {
        pageTitle: 'Update role',
        breadcrumb: [
          {
            text: 'Update role',
            active: true
          },
        ],
        requiresAuth: true
      },
    },
    // Banned users
    {
      path: '/banned-users',
      name: 'banned-users',
      component: () => import('@/views/user/BannedUsers.vue'),
      meta: {
        pageTitle: 'Banned Users',
        breadcrumb: [
          {
            text: 'Banned users',
            active: true
          },
        ],
        requiresAuth: true
      },
    },
    {
      path: '/user-questionnaires/:id',
      name: 'user-questionnaires',
      component: () => import('@/views/user/UserQuestionnaires.vue'),
      meta: {
        pageTitle: 'User Questionnaires',
        breadcrumb: [
          {
            text: 'Questionnaires',
            active: true
          },
          {
            text: 'User questionnaires',
            active: true
          },
        ],
        requiresAuth: true
      },
    },
    {
      path: '/user-submissions/:id',
      name: 'user-submissions',
      component: () => import('@/views/user/UserSubmissions.vue'),
      meta: {
        pageTitle: 'Questionnaire submissions',
        breadcrumb: [
          {
            text: 'Questionnaire submissions',
            active: true
          },
          {
            text: 'User questionnaire submissions',
            active: true
          },
        ],
        requiresAuth: true
      },
    },
    {
      path: '/questionnaire-submission/:submission_id',
      name: 'questionnaire-submission',
      component: () => import('@/views/user/QuestionnaireSubmission.vue'),
      meta: {
        pageTitle: 'Questionnaire submissions',
        breadcrumb: [
          {
            text: 'Questionnaire submissions',
            active: true
          }
        ],
        requiresAuth: true
      },
    },
    // Blacklist words
    {
      path: '/blacklist-words',
      name: 'blacklist-words',
      component: () => import('@/views/administration/BlacklistWords.vue'),
      meta: {
        pageTitle: 'Blacklisted words',
        breadcrumb: [
          {
            text: 'Blacklisted words',
            active: true,
          },
        ],
        requiresAuth: true
      },
    },
    // Report types
    {
      path: '/report-types',
      name: 'report-types',
      component: () => import('@/views/administration/report-type/ReportTypeList.vue'),
      meta: {
        pageTitle: 'Report types',
        breadcrumb: [
          {
            text: 'Report types',
            active: true,
          },
        ],
        requiresAuth: true
      },
    },
    {
      path: '/report-type/create',
      name: 'report-type-create',
      component: () => import('@/views/administration/report-type/ReportTypeCreate.vue'),
      meta: {
        pageTitle: 'Create new report type',
        breadcrumb: [
          {
            text: 'Create new report type',
            active: true,
          },
        ],
        requiresAuth: true
      },
    },
    {
      path: '/report-type/:id',
      name: 'report-type-update',
      component: () => import('@/views/administration/report-type/ReportTypeUpdate.vue'),
      meta: {
        pageTitle: 'Update report type',
        breadcrumb: [
          {
            text: 'Update report type',
            active: true,
          },
        ],
        requiresAuth: true
      },
    },
    // reported messages
    {
      path: '/reported-messages',
      name: 'reported-messages',
      component: () => import('@/views/administration/ReportedMessages/ReportedMessages.vue'),
      meta: {
        pageTitle: 'Reported Messages',
        breadcrumb: [
          {
            text: 'Reported messages',
            active: true,
          },
        ],
        requiresAuth: true
      },
    },
    // Toolkits
    {
      path: '/learn-and-develop',
      name: 'learn-and-develop',
      component: () => import('@/views/toolkit/ToolkitList.vue'),
      meta: {
        pageTitle: 'Learn and Develop',
        breadcrumb: [
          {
            text: 'Learn and Develop',
            active: true
          },
          {
            text: 'List',
            active: true
          },
        ],
        requiresAuth: true
      },
    },
    // COMMENTED OUT FOR NOW... Request haulted
    /*{ 
      path: '/learn-and-develop-videos',
      name: 'learn-and-develop-videos',
      component: () => import('@/views/toolkit/videos/ToolkitVideosList.vue'),
      meta: {
        pageTitle: 'Learn and Develop Videos',
        breadcrumb: [
          {
            text: 'Learn and Develop Videos',
            active: true
          },
          {
            text: 'Videos List',
            active: true
          },
        ],
        requiresAuth: true
      },
    },
    {
      path: '/learn-and-develop-videos/create',
      name: 'learn-and-develop-videos-create',
      component: () => import('@/views/toolkit/videos/ToolkitVideoCreate.vue'),
      meta: {
        pageTitle: 'Learn and Develop video',
        breadcrumb: [
          {
            text: 'Learn and Develop video',
            active: true
          },
          {
            text: 'Create new video',
            active: true
          },
        ],
        requiresAuth: true
      },
    },
    {
      path: '/learn-and-develop-video/edit/:id',
      name: 'learn-and-develop-video-edit',
      component: () => import('@/views/toolkit/videos/ToolkitVideoUpdate.vue'),
      meta: {
        pageTitle: 'Learn and Develop - Update video',
        breadcrumb: [
          {
            text: 'Learn and Develop',
            active: true
          },
          {
            text: 'Update video',
            active: true
          },
        ],
        requiresAuth: true
      },
    },*/
    {
      path: '/learn-and-develop/create',
      name: 'learn-and-develop-create',
      component: () => import('@/views/toolkit/ToolkitCreate.vue'),
      meta: {
        pageTitle: 'Learn and Develop',
        breadcrumb: [
          {
            text: 'Learn and Develop',
            active: true
          },
          {
            text: 'Create new',
            active: true
          },
        ],
        requiresAuth: true
      },
    },
    {
      path: '/learn-and-develop/edit/:id',
      name: 'learn-and-develop-edit',
      component: () => import('@/views/toolkit/ToolkitUpdate.vue'),
      meta: {
        pageTitle: 'Learn and Develop - Update',
        breadcrumb: [
          {
            text: 'Learn and Develop',
            active: true
          },
          {
            text: 'Update',
            active: true
          },
        ],
        requiresAuth: true
      },
    },
    {
      path: '/learn-and-develop/preview/:id',
      name: 'learn-and-develop-preview',
      component: () => import('@/views/toolkit/ToolkitPreview.vue'),
      meta: {
        pageTitle: 'Learn and Develop - Preview',
        breadcrumb: [
          {
            text: 'Learn and Develop',
            active: true
          },
          {
            text: 'Preview',
            active: true
          },
        ],
        requiresAuth: true
      },
    },
    // Toolkit categories
    {
      path: '/learn-and-develop/categories',
      name: 'learn-and-develop-categories',
      component: () => import('@/views/toolkit/category/ToolkitCategoryList.vue'),
      meta: {
        pageTitle: 'Learn and Develop Categories',
        breadcrumb: [
          {
            text: 'Categories',
            active: true
          },
          {
            text: 'List',
            active: true
          },
        ],
        requiresAuth: true
      },
    },
    {
      path: '/learn-and-develop/categories/create',
      name: 'learn-and-develop-categories-create',
      component: () => import('@/views/toolkit/category/ToolkitCategoryCreate.vue'),
      meta: {
        pageTitle: 'Learn and Develop',
        breadcrumb: [
          {
            text: 'Categories',
            active: true
          },
          {
            text: 'Create new',
            active: true
          },
        ],
        requiresAuth: true
      },
    },
    {
      path: '/learn-and-develop/categories/edit/:id',
      name: 'learn-and-develop-categories-edit',
      component: () => import('@/views/toolkit/category/ToolkitCategoryUpdate.vue'),
      meta: {
        pageTitle: 'Learn and Develop - Update',
        breadcrumb: [
          {
            text: 'Categories',
            active: true
          },
          {
            text: 'Update',
            active: true
          },
        ],
        requiresAuth: true
      },
    },
    
    // Profile, Auth and Error
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/Profile.vue'),
      meta: {
        pageTitle: 'Your profile',
        breadcrumb: [
          {
            text: 'Profile',
            active: true,
          },
        ],
        requiresAuth: true
      },
    },
    // Auth
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/auth/Login.vue'),
      meta: {
        layout: 'blank',
        hideForAuth: true
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        requiresAuth: true
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
  scrollBehavior () {
    document.getElementById('app').scrollIntoView();
  }
})

router.beforeEach((to, from, next) => {
  //Permissions for logged and not logged users

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Auth is necessary
    if (store.state.app.userData == null || store.state.app.userData.length == 0) {
        next({ path: '/login' });
    } else {
        next();
    }
  } else {
      next();
  }

  if (to.matched.some(record => record.meta.hideForAuth)) {
    // If you are logged in, just go to the dashboard
    if (store.state.app.userData != null) {
      if(store.state.app.userData.length != 0) {
        next({ path: '/' });
      } else {
        next();
      }
    } else {
        next();
    }
  } else {
      next();
  }
  
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
