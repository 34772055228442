import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'
import vueDebounce from 'vue-debounce'
import VueYoutube from 'vue-youtube'
import vueVimeoPlayer from 'vue-vimeo-player'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

import axios from '@axios'
import moment from 'moment'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// debounce
Vue.use(vueDebounce)

// youtube & vimeo
Vue.use(VueYoutube)
Vue.use(vueVimeoPlayer)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

// EventBus
const EventBus = new Vue();
export default EventBus;

Vue.config.productionTip = false

// Filters
Vue.filter('prepareDate', function (value) {
  if (!value) return ''
  // Get format "May 28, 2021, 13:50:16"
  value = moment(
          value
  ).format("ll, HH:mm:ss");

  return value
})

Vue.filter('notificationDate', function (value) {
  if (!value) return ''
  // Get format "a minute ago, an hour ago, 2 days ago..."
  value = moment(
          value
  ).fromNow();

  return value
})

// Mixins (methods)
Vue.mixin({
  methods: {
    makeAvatar(username) {
      // Get username and return avatar with first letter
      if(username == "" || username == undefined || username == null) {
        return "https://eu.ui-avatars.com/api/?name=n";
      }
      return "https://eu.ui-avatars.com/api/?name=" + username.charAt(0)
    }
  },
})

// Filters
Vue.filter('strippedHTML', function (string) {
  if (!string) return ''
  return string.replace(/<\/?[^>]+>/ig, " "); 
})

// Request interceptor for API calls
axios.interceptors.request.use(
  async config => {
    const userDataLS = localStorage.getItem('userData');

    if(userDataLS != null) {
      const userData = JSON.parse(userDataLS)
      const token = `${userData.token_type} ${userData.accessToken}`;
  
      config.headers = { 
        'Authorization': token,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }
    return config;
  },
  error => {
    Promise.reject(error)
});

// 401 response interceptor
axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {

  let error_msg = {
    'response': error.response,
    'user': localStorage.getItem('userData')
  }

  let formData = new FormData;
  formData.append('error_message', JSON.stringify(error_msg))

  axios
  .post("/api/error-log", formData)
  .then((res) => {
  })
  .catch((err) => {
  })

  if (error.response.status === 401 || error.response.status === 403) {
    //Remove userData from localStorage and redirect to login
    store.commit('app/removeUserData')
    localStorage.removeItem('userData')
    
    router.push({ path: '/login', query: { sessionExp: '1' } }).catch(()=>{});
  } else {
    return Promise.reject(error);
  }
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
