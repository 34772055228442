export default {
    namespaced: true,
    state: {
      questionnaireComponent: 'SetupQuestionnaireDetails',
      question: {},
    },
    getters: {},
    mutations: {
      // Choosen component in Home view 
      setQuestionnaireComponent: (state, payload) => {
        state.questionnaireComponent = payload
      },
  
      // Choosen Question object for view
      setQuestion: (state, payload) => {
        state.question = payload
      },
    },
    actions: {
 
    }
  }
  