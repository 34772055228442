import axios from "@axios"

export default {
    namespaced: true,
    state: {
        worksheetList: [],
        currentToolkit: {},
        toolkitProgress: 0,
        worksheetIndex: 0,
    },
    getters: {
        worksheetList(state) {
            return state.worksheetList;
        },
        currentToolkit(state) {
            return state.currentToolkit;
        },
        toolkitProgress(state) {
            return state.toolkitProgress;
        },
        worksheetIndex(state) {
            return state.worksheetIndex;
        },
    },
    mutations: {
        addWorksheetList(state, worksheet) {
            state.worksheetList.push(worksheet)
        },
        removeWorksheetList(state) {
            state.worksheetList.splice(this.worksheetList, 1)
        },
        setCurrentToolkit(state, toolkitId) {
            axios.get("/api/auth/admin/toolkits/" + toolkitId)
            .then((res) => {
                if(res != undefined) {
                    state.currentToolkit = res.data
                }
            })
            .catch((err) => {
                console.log(err);
            })
        },
        toolkitProgress(state, progress) {
            state.toolkitProgress = progress;
        },
        // worksheet index
        worksheetIndex(state, index) {
            state.worksheetIndex = index;
        },
        incrementWorksheetIndex(state) {
            state.worksheetIndex++
        },
        decrementWorksheetIndex(state) {
            state.worksheetIndex--
        },
        resetWorksheetIndex(state) {
            state.worksheetIndex = 0
        },
    },
    actions: {
        addWorksheetList({ commit }, worksheet) {
            commit('addWorksheetList', worksheet)
        },
        removeWorksheetList({ commit }) {
            commit('removeWorksheetList')
        },
        setToolkitProgress({commit}, progress) {
            commit('toolkitProgress', progress)
        },
        setCurrentToolkit({commit}, toolkitId) {
            commit('setCurrentToolkit', toolkitId)
        },
        // worksheet index
        setWorksheetIndex({commit}, index) {
            commit('worksheetIndex', index)
        },
        incrementWorksheetIndex({commit}) {
            commit('incrementWorksheetIndex')
        },
        decrementWorksheetIndex({commit}) {
            commit('decrementWorksheetIndex')
        },
        resetWorksheetIndex({commit}) {
            commit('resetWorksheetIndex')
        },
    }
}
