import { $themeBreakpoints } from '@themeConfig'
import Pusher from 'pusher-js'
import * as api_credentials from "../../api-credents";

function getUserData() {
  try {
    return JSON.parse(localStorage.getItem('userData'))
  } catch(error) {
    localStorage.removeItem("userData");
  }
}

function initPusher() {
  let pusher = new Pusher(api_credentials.pusher_id, {
    cluster: 'eu',
    authEndpoint: api_credentials.pusher_auth
  })

  return pusher
}

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    userData: getUserData(),
    pusher: initPusher(),

    // Stripe Products

    // LIVE SETTINGS
    // productFullPackage: "price_1JUA2TIonXgjvwyRBujPGRNU",
    // stripeAPIToken: "pk_live_51J3N8cIonXgjvwyRurry1O87sNYCk3HmhOuBNAQO7XGvKc2JQH0HEzEcFvy6zVcRvP7GsiMZFK2ucY9nmOKdubbm00wfnuFmxM",
    
    // TEST SETTINGS
    productFullPackage: "price_1JsOlgIonXgjvwyRWQC5XEIX",
    stripeAPIToken: "pk_test_51J3N8cIonXgjvwyROayMLYnKnL2CguYutNbKE65IwqzOvN7MivaQOduKSc90twbm986wXeMd9vShSfHNuVYbQThn00TeaB7HAT",
    
    fullPackagePrice: "9.99",
    
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    setUserData: (state, payload) => {
      state.userData = payload
      // Update value in localStorage
      localStorage.setItem('userData', JSON.stringify(payload))
    },
    removeUserData: (state) => {
      state.userData = []
      // Remove value from localStorage
      localStorage.removeItem('userData')
    },
  },
  actions: {}

}
